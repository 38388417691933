// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCtOF5qIkHWDHGO0HBBs2yql75ud8N8Wno",
  authDomain: "the-og-lunchme.firebaseapp.com",
  databaseURL: "https://the-og-lunchme.firebaseio.com",
  projectId: "the-og-lunchme",
  storageBucket: "the-og-lunchme.appspot.com",
  messagingSenderId: "14999976032",
  appId: "1:14999976032:web:d78c150e2fa43fe581ab05",
  measurementId: "G-EJ0QVXJPZX"
};

export default firebaseConfig